<template>
  <div class="page">
    <div class="store_title">
      <img class="logo" :src="require('@/asset/logo.png')"/>
      <img class="split_icon" :src="require('@/asset/icon/split-icon.png')"/>
      <p class="title">{{businessName.length > 5 ? businessName.slice(0, 5) + '...' : businessName}}</p>
    </div>
    <p class="role">{{ data.roleName }}</p>
    <canvas ref="canvas" class="canvas"></canvas>
    <div class="date">
      <p class="times">{{ data.scanNumber }}次</p>
      <p class="validDate">{{ data.validDate }}</p>
    </div>
    <div class="store">
      <ul>
        <li v-for="(item, index) in maxStoreList" :key="index" class="store_item"><span class="check">&#10004</span>{{ item }}</li>
        <li v-if="data.storeNameList.length >= 8" class="store_tip">扫码查看详情</li>
      </ul>
    </div>
    <div class="root">
      <ul>
        <p class="title"><span class="circle"></span>功能权限</p>
        <li v-for="(item, index) in data.rolePrivilegelist" :key="index" class="root_item"><span class="check">&#10004</span>{{ item }}</li>
      </ul>
      <ul class="info">
        <p class="title"><span class="circle"></span>消息通知权限</p>
        <li v-for="(item, index) in data.informPrivilegelist" :key="index" class="root_item"><span class="check">&#10004</span>{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  props: {
    data: {
      require: true
    }
  },
  data() {
    return {
      businessName: sessionStorage.getItem('currentBusiness')
    }
  },
  computed: {
    maxStoreList() {
      return this.data.storeNameList.slice(0, 8)
    }
  },
  watch: {
    data(nV) {
      const canvas = this.$refs.canvas
      let { url } = nV
      if (url && canvas) {
        QRCode.toCanvas(
          canvas,
          url,
          {
            width: 90,
            height: 90
          },
          error => {
            if (error) console.error(error)
          }
        )
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  width: 375px;
  height: 805px;
  margin: 0 auto;
  background-image: url('../../../../asset/personPoster.png');
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  .store_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
    .logo {
      display: block;
      width: 45px;
      height: 45px;
    }
    .split_icon {
      display: block;
      width: 22px;
      height: 22px;
      margin: 0 20px;
    }
    .title {
      font-size: 28px;
      color: #ffffff;
      flex-shrink: 0;
    }
  }
  .role {
    width: 100%;
    font-size: 20px;
    color: #ffffff;
    position: absolute;
    top: 164px;
    text-align: center;
  }
  .canvas {
    position: absolute;
    left: 24.7%;
    top: 248px;
    border: 2px solid #ff4e53;
    border-radius: 5px;
  }
  .date {
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    top: 268px;
    right: 4%;
    .times {
      margin-bottom: 28px;
    }
    .times,
    .validDate {
      width: 150px;
      text-align: center;
    }
  }
  .store {
    position: absolute;
    top: 424px;
    left: 4%;
    .store_item,
    .store_tip {
      width: 150px;
      font-size: 12px;
      color: #ffffff;
      list-style: none;
      margin-bottom: 12px;
    }
  }
  .root {
    position: absolute;
    top: 424px;
    left: 60%;
    .circle {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #ffffff;
      border: 1px solid #ff4e53;
      border-radius: 50%;
      margin-right: 10px;
    }
    .title {
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 14px;
    }
    .root_item {
      width: 150px;
      font-size: 12px;
      color: #ffffff;
      list-style: none;
      margin-bottom: 12px;
    }
    .info {
      margin-top: 30px;
    }
  }
  .check {
    color: #ffffff;
    margin-right: 10px;
  }
}
</style>
