<route>
{
  "meta": {
    "auth": "personAdd"
  }
}
</route>

<template>
  <div>
    <el-dialog :close-on-click-modal="false" :visible.sync="visible" title="添加店员">
      <el-steps :active="dialog.active" simple>
        <el-step title="人员角色配置" icon="el-icon-edit" @click.native="stepClick(0)"></el-step>
        <el-step title="绑定人员" icon="el-icon-s-custom" @click.native="stepClick(1)"></el-step>
      </el-steps>
      <div class="role-conf panel" v-show="dialog.active === 0">
        <el-form label-width="140px" label-position="right">
          <el-form-item label="人员角色">
            <el-radio-group v-model="params.roleId">
              <el-radio
                @change="handleRoleChange(index)"
                v-for="(item, index) in role.list"
                :key="index"
                :label="item.id"
                :style="{ color: item.color }"
                >{{ item.roleName }}</el-radio
              >
            </el-radio-group>
            <bm-tip>红色的是平台商家权限</bm-tip>
          </el-form-item>
          <el-form-item label="绑定商家下门店">
            <el-radio @change="handleChange" v-model="params.allStorePrivilege" :label="1">全部门店</el-radio>
            <el-radio @change="handleChange" v-model="params.allStorePrivilege" :label="0">部分门店</el-radio>
          </el-form-item>
          <el-form-item label="所属门店">
            <el-checkbox-group v-model="params.checkedStore">
              <el-checkbox
                v-for="(item, index) in storeList"
                :label="item.id"
                :disabled="dialog.isDisabled"
                :key="index"
                >{{ item.storeTitle }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <div class="title">拥有的权限</div>
          <div class="permission">
            <div class="item" v-for="(item, index) in rolePrivilegelist" :key="index">
              <i class="el-icon-circle-check icon"></i>
              {{ item.privilegeName }}
            </div>
          </div>

          <div class="title">消息通知</div>
          <div class="permission">
            <div class="item" v-for="(item, index) in informPrivilegelist" :key="index">
              <i class="el-icon-circle-check icon"></i>
              {{ item.privilegeName }}
            </div>
          </div>
        </el-form>
      </div>
      <div class="panel bind-user" v-show="dialog.active === 1">
        <div style="text-align: center; margin: 16px 0">
          <el-button @click="qrCode">扫码绑定</el-button>
          <el-button @click="dialog.showCodeOrPhone = 3">手机号绑定</el-button>
        </div>

        <div v-if="dialog.showCodeOrPhone === 2" style="text-align: center">
          <el-form style="width: 300px; margin: auto">
            <el-form-item label="有效次数">
              <el-input-number v-model="params.scanNumber" :min="1" :max="100"></el-input-number>
            </el-form-item>
            <el-form-item label="有效期">
              <el-radio-group v-model="params.validPeriod">
                <el-radio :label="0">24小时</el-radio>
                <el-radio :label="1">七天</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-button @click="confirmShowQrCode" type="primary">生成二维码</el-button>
          </el-form>
          <!-- <canvas ref="canvas"></canvas> -->
        </div>
        <div v-if="dialog.showCodeOrPhone === 3">
          <div class="search">
            <el-input
              v-model="dialog.phoneNumber"
              class="input"
              placeholder="请输入手机号进行查找"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              outline
            ></el-input>
            <el-button @click="getUser">搜索</el-button>
          </div>
          <el-table :data="searchResult.data" ref="multipleTable" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              v-for="col in Object.keys(searchResult.label)"
              :key="col"
              :prop="col"
              :label="searchResult.label[col]"
            >
              <template slot-scope="{ row }">
                <img :src="row[col] | thumbSmall" v-if="col === 'avatarUrl'" />
                <div v-else>{{ row[col] }}</div>
              </template>
            </el-table-column>
          </el-table>
          <div class="selected">
            已选

            <span v-for="(user, index) in searchResult.selected" :key="index">
              <img :src="user.avatarUrl | thumbSmall" />
              <i class="deleteUser el-icon-close" @click="handleRemovePic(index)"></i>
            </span>
          </div>
        </div>
      </div>

      <el-button v-show="dialog.active === 0 || dialog.showCodeOrPhone === 3" class="btn" @click="next()">{{
        dialog.active === 0 ? '下一步' : '提交'
      }}</el-button>
    </el-dialog>
    <el-drawer title="添加店员" :visible.sync="showPoster" direction="rtl">
      <poster :data="posterData" />
    </el-drawer>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import Poster from './poster'
import { formatTime } from '@/util/filter'

export default {
  components: {
    Poster
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    store: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      dialog: {
        active: 0,
        phoneNumber: '',
        isDisabled: false,
        showCodeOrPhone: 0 // 在第二步的时候， 2是扫码，3 是手机号绑定
      },
      params: { checkedStore: [], roleName: '', allStorePrivilege: 0, roleId: 0, validPeriod: 0, scanNumber: 1 },
      visible: this.show,
      informPrivilegelist: [],
      rolePrivilegelist: [],

      role: {
        list: []
      },
      storeList: [],
      searchResult: {
        label: {
          id: 'ID',
          wxNickname: '昵称',
          avatarUrl: '头像',
          phoneNumber: '电话'
        },
        data: [],
        selected: []
      },
      showPoster: false,
      posterData: {
        url: '',
        roleName: '',
        storeNameList: [],
        informPrivilegelist: [],
        rolePrivilegelist: [],
        scanNumber: 0,
        validDate: ''
      }
    }
  },

  watch: {
    visible(v) {
      this.dialog.active = 0
      if (!v) this.$emit('close')
    },
    show(v) {
      this.visible = v
      if (v) {
        this.searchResult.data = []
        this.dialog.phoneNumber = ''
        this.searchResult.selected = []
        this.params.roleId = ''
        this.params.checkedStore = []
        this.store.selected = ''
        this.params.allStorePrivilege = 0
        this.params.scanNumber = 1
        this.params.validPeriod = 0
        this.dialog.isDisabled = false
      }
    },
    store: {
      handler(val) {
        this.storeList = val.list
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    qrCode() {
      this.dialog.showCodeOrPhone = 2
    },
    confirmShowQrCode() {
      let storeNameList = []
      this.showPoster = true
      let storeList = []
      if (this.params.allStorePrivilege === 0) {
        this.storeList.forEach(item => {
          if (this.params.checkedStore.includes(item.id)) {
            let obj = {}
            obj.storeId = item.id
            obj.storeName = item.storeTitle
            storeList.push(obj)
          }
        })
        storeNameList = this.params.checkedStore.map(item => {
          let curItem = this.storeList.find(i => +i.id === +item)
          return curItem && curItem.storeTitle
        })
      } else {
        storeList = this.params.checkedStore
        storeNameList = this.storeList.map(item => item.storeTitle)
      }
      let privilegeList = []
      this.informPrivilegelist.forEach(item => {
        let obj = { privilegeId: item.id, privilegeName: item.privilegeName, privilegeType: item.typeStatus }
        privilegeList.push(obj)
      })
      this.rolePrivilegelist.forEach(item => {
        let obj = { privilegeId: item.id, privilegeName: item.privilegeName, privilegeType: item.typeStatus }
        privilegeList.push(obj)
      })
      let params = {
        businessId: this.$store.getters['vendor/params/getVendorId'],
        roleId: this.params.roleId,
        businessName: this.$store.getters['vendor/params/getVendorName'],
        storeList: storeList,
        privilegeList: privilegeList,
        roleName: this.params.roleName,
        allStorePrivilege: this.params.allStorePrivilege,
        scanNumber: this.params.scanNumber,
        validPeriod: this.params.validPeriod
      }

      this.$http
        .post('/boom-center-admin-service/sysAdmin/scanCode', params)
        .then(res => {
          let url

          if (this.$store.getters['global/isEnvProd']) {
            url = 'https://miniapp.egg.tanchi.shop/bind?id=' + res
          } else {
            url = 'https://miniapp.egg.tanchi0757.shop/bind?id=' + res
          }
          this.$nextTick(() => {
            let curTime = new Date().getTime()
            let day = formatTime(curTime + 86400000)
            let day7 = formatTime(curTime + 604800000)

            this.posterData = {
              url,
              roleName: this.params.roleName,
              storeNameList,
              rolePrivilegelist: this.rolePrivilegelist.map(item => item.privilegeName),
              informPrivilegelist: this.informPrivilegelist.map(item => item.privilegeName),
              scanNumber: this.params.scanNumber,
              validDate: this.params.validPeriod === 0 ? day : day7
            }
            const canvas = this.$refs.canvas
            if (canvas) {
              QRCode.toCanvas(canvas, url, error => {
                if (error) console.error(error)
              })
            }
          })
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },

    handleChange(val) {
      this.params.checkedStore = []
      this.dialog.isDisabled = false
      if (val == 1) {
        this.storeList.forEach(item => {
          let obj = {}
          obj.storeId = item.id
          obj.storeName = item.storeTitle
          this.params.checkedStore.push(obj)
        })
        this.dialog.isDisabled = true
      }
    },
    // 处理角色改变
    handleRoleChange(index) {
      this.params.roleName = this.role.list[index].roleName
      this.informPrivilegelist = this.role.list[index].informPrivilegelist
      this.rolePrivilegelist = this.role.list[index].rolePrivilegelist
    },
    // 获取角色列表

    getDefaultRole() {
      return this.$http
        .get('/boom-center-admin-service/sysAdmin/businessRole/business/default/roleList', {
          params: {
            size: 1000
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    getCurrentBusinessRole() {
      return this.$api.vendor
        .vendor('getStoreRoleList', {
          params: { businessId: this.$store.getters['vendor/params/getVendorId'], size: 1000 }
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    getRole() {
      Promise.all([this.getDefaultRole(), this.getCurrentBusinessRole()]).then(res => {
        console.log(res)

        let arr = res[0].list.map(item => {
          item.color = '#e71414'
          return item
        })
        let arr1 = res[1].list.map(item => {
          item.color = '#333333'
          return item
        })
        this.role.list.push(...arr, ...arr1)
        console.log(this.role.list)
        this.params.roleId = this.role.list[0].id
      })
    },
    // 根据手机号搜索用户
    getUser() {
      let params = {
        params: {
          phoneNumber: this.dialog.phoneNumber
        }
      }
      this.$http
        .get('boom-center-user-service/sysAdmin/members/getMemberInfoByPurePhoneNumber/' + this.dialog.phoneNumber)
        .then(res => {
          this.searchResult.data = [res]
        })
        .catch(err => {
          this.searchResult.data = []
          this.$message.error(err.msg || '操作失败')
        })
    },
    // 步骤条
    stepClick(index) {
      if (index < this.dialog.active) {
        this.dialog.active = index
      }
    },
    // 下一步
    next(type) {
      if (!this.params.roleId) {
        this.$message.error('请先选择角色')
        return
      }
      if (this.params.checkedStore.length === 0) {
        this.$message.error('请先选择门店')
        return
      }
      if (this.dialog.active === 0) {
        this.dialog.active = 1
        this.$nextTick(() => {
          if (this.$refs.canvas) {
            this.$refs.canvas.height = this.$refs.canvas.height
          }
        })
      } else {
        let storeList = []
        if (this.params.allStorePrivilege === 1) {
          this.storeList.forEach(item => {
            storeList.push(item.id)
          })
        } else {
          storeList = this.params.checkedStore
        }
        let params = {
          businessId: this.$store.getters['vendor/params/getVendorId'],
          memberPhoneList: this.searchResult.selected.map(item => {
            return item.phoneNumber
          }),
          roleId: this.params.roleId,
          storeIdList: storeList,
          allStorePrivilege: this.params.allStorePrivilege
        }
        const loading = this.$loading({
          lock: true,
          text: '正在提交，请稍候',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$api.vendor
          .vendor('postBusinessUser', params)
          .then(res => {
            this.$emit('close')
            this.$message.success('添加成功')
            this.$emit('getList')
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
          .finally(() => {
            loading.close()
          })
      }
    },
    handleRemovePic(index) {
      this.searchResult.selected.splice(index, 1)
      this.$refs.multipleTable.clearSelection()
    },
    handleSelectionChange(e) {
      if (e.length === 0) {
        this.searchResult.selected.forEach((item, index) => {
          if (this.searchResult.data && this.searchResult.data[0] && this.searchResult.data[0].id === item.id) {
            this.searchResult.selected.splice(index, 1)
          }
        })
        return
      }
      if (this.searchResult.selected.length !== 0) {
        let isSelect = true
        this.searchResult.selected.forEach(item => {
          if (e[0].id === item.id) {
            isSelect = false
          }
        })
        if (isSelect) {
          let obj = {}
          obj.avatarUrl = e[0].avatarUrl
          obj.id = e[0].id
          obj.phoneNumber = e[0].purePhoneNumber
          obj.wxNickname = e[0].wxNickname
          obj.wxOpenId = e[0].wxOpenId
          this.searchResult.selected.push(obj)
        }
      } else {
        this.searchResult.selected.push({
          avatarUrl: e[0].avatarUrl,
          phoneNumber: e[0].purePhoneNumber,
          wxNickname: e[0].wxNickname,
          wxOpenId: e[0].wxOpenId,
          id: e[0].id
        })
      }
    }
  },
  created() {
    this.getRole()
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  padding: 30px;
  border-radius: 20px;
}
.panel {
  padding: 30px 0;
  .title {
    font-size: 14px;
    font-weight: bold;
    color: black;
    margin-bottom: 20px;
  }
}
.role-conf {
  .permission {
    margin-bottom: 30px;
    .item {
      display: inline-flex;
      margin-right: 15px;
      .icon {
        color: #e7141a;
      }
    }
  }
}
.bind-user {
  .tip {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }
  .search {
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    .input {
      margin-right: 20px;
      width: 266px !important;
    }
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .selected {
    display: flex;
    align-items: center;
    margin-top: 30px;
    img {
      margin-left: 10px;
    }
    .deleteUser {
      vertical-align: top;
      font-size: 17px;
      color: red;
    }
  }
}

.btn {
  border-radius: 30px;
}
</style>
